import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h1>Lostboy$</h1>
      <img src="kjaert.jpeg"></img>
      </header>
    </div>
  );
}

export default App;
